import React, { useState, useRef, useEffect } from "react";
import "./navbar.css"; // Import the CSS file
import logo from "../../assests/navbar/header-logo.avif";
import logo2 from "../../assests/navbar/footer-logo.avif";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useLocation, matchPath } from "react-router-dom";
import Navbarmb from "./navbarmb";
import Walkinbook from "../walkin-book/walkinbook";
import Stickybar from "./stickybar.jsx/stickybar";
import Altfkey from "../altfkey-word/altfkeys";

const Navbar = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const itemRefs = useRef([]);
  const [sidebaroptions, setsidebaroptions] = useState(null);
  const [isSticky, setIsSticky] = useState(true);
  const [hoveredOption, setHoveredOption] = useState(null);

  const hoverlinks = [
    {
      title: "Locations",
      link: "/coworking-space/",
      options: [
        {
          name: "Coworking Space in Gurgaon",
          link: "/coworking-space/gurgaon/",
        },
        { name: "Coworking Space in Noida", link: "/coworking-space/noida/" },
        { name: "Coworking Space in Delhi", link: "/coworking-space/delhi/" },
        {
          name: "Coworking Space in Hyderabad",
          link: "/coworking-space/hyderabad/",
        },
      ],
    },
    {
      title: "Workspace Solutions",
      link: "/workspace-solutions/",
      options: [
        {
          name: "Private Office",
          link: "/workspace-solutions/private-office/",
          subOptions: [
            {
              name: "Team Rooms",
              link: "/workspace-solutions/private-office/team-rooms/",
            },
            {
              name: "Director Cabins",
              link: "/workspace-solutions/private-office/director-cabins/",
            },
            {
              name: "Office Suites",
              link: "/workspace-solutions/private-office/office-suites/",
            },
          ],
        },
        {
          name: "Virtual Office",
          link: "/workspace-solutions/virtual-office/",
          subOptions: [
            {
              name: "Business Address Plan",
              link: "/workspace-solutions/virtual-office/business-address-plan/",
            },
            {
              name: "GST Registration Plan",
              link: "/workspace-solutions/virtual-office/gst-registration-plan/",
            },
            {
              name: "Business Registration Plan",
              link: "/workspace-solutions/virtual-office/business-registration-plan/",
            },
          ],
        },
        {
          name: "Flexible Office",
          link: "/workspace-solutions/flexible-office/",
          subOptions: [
            {
              name: "Day Pass",
              link: "/workspace-solutions/flexible-office/day-pass/",
            },
            {
              name: "Meeting Rooms",
              link: "/workspace-solutions/flexible-office/meeting-rooms/",
            },
          ],
        },
      ],
    },
  ];

  const location = useLocation();

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [location, setIsSidebarOpen]);

  const [openOption, setOpenOption] = useState(null); // State for options
  const [openSubOption, setOpenSubOption] = useState(null); // State for sub-options

  const toggleOption = (index) => {
    setOpenOption((prev) => (prev === index ? null : index));
    setOpenSubOption(null); // Close sub-options when toggling main options
  };

  const toggleSubOption = (key) => {
    setOpenSubOption((prev) => (prev === key ? null : key));
  };

  const hiddenRoutes = [
    "/noida-landing-page/",
    "/Virtual-office-landing-page/",
    "/landing-page/coworking-spaces/",
    "/our-story/",
    "/business-card/:cardId",
  ];
  const hideNavbarFooter = hiddenRoutes.some((route) =>
    matchPath({ path: route, end: true }, location.pathname)
  );

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (hideNavbarFooter) {
  //       const chatWidget = document.querySelector(
  //         'script[src*="watiWidget.js"]'
  //       );
  //       if (chatWidget) {
  //         chatWidget.remove();
  //       }

  //       const chatButton = document.querySelector(".wati-chat-button-class");
  //       if (chatButton) {
  //         chatButton.style.display = "none";
  //       }
  //     } else {
  //       const existingScript = document.querySelector(
  //         'script[src*="watiWidget.js"]'
  //       );
  //       if (!existingScript) {
  //         const url =
  //           "https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?74813";
  //         const script = document.createElement("script");
  //         script.type = "text/javascript";
  //         script.async = true;
  //         script.src = url;

  //         const options = {
  //           enabled: true,
  //           chatButtonSetting: {
  //             backgroundColor: "#e15252",
  //             ctaText: "Chat",
  //             borderRadius: "25",
  //             marginLeft: "0",
  //             marginRight: "20",
  //             marginBottom: "20",
  //             ctaIconWATI: false,
  //             position: "right",
  //           },
  //           brandSetting: {
  //             brandName: "alt.f coworking",
  //             brandSubTitle: "undefined",
  //             brandImg:
  //               "https://www.wati.io/wp-content/uploads/2023/04/Wati-logo.svg",
  //             welcomeText: "Hi there!\nHow can we help you?",
  //             messageText: "Hello, %0A I have a question about {{page_link}}",
  //             backgroundColor: "#d36161",
  //             ctaText: "Chat",
  //             borderRadius: "25",
  //             autoShow: false,
  //             phoneNumber: "919958080053",
  //           },
  //         };

  //         script.onload = function () {
  //           if (typeof window.CreateWhatsappChatWidget === "function") {
  //             window.CreateWhatsappChatWidget(options);
  //           } else {
  //             console.error("WhatsApp Chat Widget is not available.");
  //           }
  //         };

  //         document.body.appendChild(script);
  //       }
  //     }
  //   }, 3000); 

  //   return () => clearTimeout(timer);
  // }, [location.pathname, hideNavbarFooter]); 


  useEffect(() => {
    const timer = setTimeout(() => {
      const isMobile = window.innerWidth <= 768;
  
      if (hideNavbarFooter || isMobile) {
        const gallaboxScript = document.querySelector(
          'script[src*="waw.gallabox.com"]'
        );
        if (gallaboxScript) {
          gallaboxScript.remove();
        }
  
        // Hide the Gallabox chat widget frame if it was already injected
        const chatWidgetFrame = document.querySelector("iframe[src*='gallabox']");
        if (chatWidgetFrame) {
          chatWidgetFrame.style.display = "none";
        }
      } else {
        // Prevent duplicate script injection
        const existingScript = document.querySelector(
          'script[src*="waw.gallabox.com"]'
        );
        if (!existingScript) {
          // Inject Gallabox chat widget script dynamically
          window.gbwawc = {
            url: "https://waw.gallabox.com",
            options: {
              waId: "919958080053",
              siteName: "alt.f coworking",
              siteTag: "Usually replies instantly.",
              siteLogo:
                "https://files.gallabox.com/67c7ff6c67848408ce0d4d62/209382bf-5218-45b1-940c-84f4a4735439-linkedinsticker1.png",
              widgetPosition: "RIGHT",
              welcomeMessage:
                "Welcome to alt.f coworking. How can we help you today?",
              brandColor: "#25D366",
              customQuestion: "I would like to know more about alt.f",
              startChatBtnTxt: "Connect me to a live agent",
              version: "v2",
              widgetPositionMarginX: 12,
              widgetPositionMarginY: 12,
            },
          };
  
          const script = document.createElement("script");
          script.async = true;
          script.src =
            "https://waw.gallabox.com/whatsapp-widget.min.js?_=" +
            encodeURIComponent(new Date().toISOString());
          document.body.appendChild(script);
        }
      }
    }, 3000);
  
    return () => clearTimeout(timer);
  }, [location.pathname, hideNavbarFooter]);

    const [isFormOpen, setIsFormOpen] = useState(false);
  
    const toggleForm = () => {
      setIsFormOpen(!isFormOpen);
    };

   
    
  
  return (
    <div className="maxwidthcontainer">

    
      <div className="d-none d-lg-block">
      <Stickybar/>
      <Walkinbook isFormOpen={isFormOpen} toggleForm={toggleForm} />

        <div className={`header ${isSticky ? "sticky" : ""}`}>
        <div
            className="d-flex flex-row justify-content-between align-items-center "
            style={{ width: "95%" }}
          >
          
            <div className="header__logo">
              <Link to="/">
                <img
                  src={isSticky ? logo : logo2}
                  alt="logo"
                  className="logo"
                  width="160"
                  height="65"
                  fetchpriority="high"
                />
              </Link>
            </div>
            <nav className="navbar d-flex flex-row justify-content-center">
              <ul className="navbar__menu">

              <li className="navbar__menu-item"  
                onClick={(e) => {
                    e.stopPropagation(); 
                    toggleForm();
                  }}>
                  <p className="navbar__link" style={{color:"#e15252"}}>
                    Book a Visit{" "}
                      <span style={{ color: "grey", marginLeft: "8px" }}>
                        {" "}
                        |
                      </span>
                  </p>
                </li>
                
                <li className="navbar__menu-item">
                  <p className="navbar__link">
                    <Link className="custom-link" to="/offers/">
                      Offers{" "}
                      <span style={{ color: "grey", marginLeft: "8px" }}>
                        {" "}
                        |
                      </span>
                    </Link>
                  </p>
                </li>

                <li className="navbar__menu-item">
                  <p className="navbar__link">
                    <Link className="custom-link" to="/channel-partners/">
                    Brokers{" "}
                      <span style={{ color: "grey", marginLeft: "8px" }}>
                        {" "}
                        |
                      </span>
                    </Link>
                  </p>
                </li>
                
                <li className="navbar__menu-item">
                  <p className="navbar__link">
                    <Link className="custom-link" to="/property-owners/">
                      Property Owners{" "}
                      <span style={{ color: "grey", marginLeft: "8px" }}>
                        {" "}
                        |
                      </span>
                    </Link>
                  </p>
                </li>


                {hoverlinks.map((item, index) => (
                  <li
                    key={index}
                    ref={(el) => (itemRefs.current[index] = el)}
                    onMouseEnter={() => setHoveredItem(index)}
                    onMouseLeave={() => setHoveredItem(null)}
                    className="navbar__menu-item"
                  >
                    <p className="navbar__link">
                      <Link className="custom-link" to={item.link}>
                        {item.title}
                      </Link>
                      <span style={{ marginLeft: "3px" }}>
                        {hoveredItem === index ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )}
                      </span>
                    </p>
                    <ul
                      className={`dropdown ${
                        hoveredItem === index ? "show" : ""
                      }`}
                    >
                      {item.options.map((option, optIndex) => (
                        <li
                          key={optIndex}
                          className="dropdown__item"
                          onMouseEnter={() => setHoveredOption(optIndex)}
                          onMouseLeave={() => setHoveredOption(null)}
                        >
                          <Link className="custom-link" to={option.link}>
                            {option.name}
                            {option.subOptions &&
                              hoveredOption === optIndex && (
                                <span>
                                  <MdOutlineKeyboardArrowRight />
                                </span>
                              )}
                          </Link>
                          {option.subOptions && (
                            <ul className="sub-dropdown">
                              {option.subOptions.map((subOption, subIndex) => (
                                <li
                                  key={subIndex}
                                  className="sub-dropdown__item"
                                >
                                  <Link
                                    className="custom-link"
                                    to={subOption.link}
                                  >
                                    {subOption.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}


                

                <li className="nav ">
                  <span
                    style={{
                      marginLeft: "8px",
                      marginRight: "10px",
                      textAlign: "end",
                    }}
                  >
                    <div className="d-flex">
                      <FaPhoneAlt
                      
                        style={{
                          color: "#e15252",
                          width: "20px",
                          marginRight: "5px",
                          marginTop: "4px",  
                        }}
                      />
                      <span  className="navnumber">+91 9958500731</span> <br />
                    </div>
                    <span className="navnumbertext">
                      Sales Enquiries Only
                    </span>
                  </span>
                </li>

                <li
                  className="navbar__link"
                  style={{ marginTop: "-8px", color: "#e15252" }}
                >
                  <Link to="/enquiry">
                    <button className=" btnenqury">Enquire</button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
    
         
        </div>


       
      </div>
    
      <div className="d-lg-none mbnavborderradius">

      {location.pathname !== '/' &&
        <div className="headbannershark d-md-none">
            <div className="scrolling-text">
              <span>
                As seen on Shark Tank India &nbsp;•&nbsp; As seen on Shark Tank
                India &nbsp;•&nbsp; As seen on Shark Tank India &nbsp;•&nbsp; As
                seen on Shark Tank India &nbsp;•&nbsp; As seen on Shark Tank
                India &nbsp;•&nbsp; As seen on Shark Tank India &nbsp;•&nbsp;
              </span>
            </div>
          </div>
      }
   
         
  

        <div className=" mobile-navbar">
          <div className="mobile-header d-flex flex-row justify-content-between align-items-center">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>



            
            <div className="d-flex gap-1 align-items-center">
            <Link className="custom-link" to="/channel-partners/">
                <span className="navsmalloffer">Brokers</span>
              </Link>
            <Link className="custom-link" to="/offers/">
                <span className="navsmalloffer">| Offers</span>
              </Link>
              <Link className="custom-link" to="/property-owners/">
                <span className="  mbpropbtn">| Property Owners</span>
              </Link>
            </div>

           

     
          </div>



        </div>

        <Navbarmb hoverlinks={hoverlinks}/>
       
      </div>

     
    </div>
  );
};

export default Navbar;
